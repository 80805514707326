import React from 'react';
import { NavLink } from 'react-router-dom';
import Styled from 'styled-components';

import Button from '../Reuseable/Button';

const Wrapper = Styled.div`
    width:  200px;
    height: 100vh;
    background-color: #22338a;
    color: white;
    padding: 10px 20px;
    position: fixed;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    z-Index: 1;
    padding-top: 100px;
    box-sizing: border-box;
    a {
        text-decoration: none;
        color: inherit;
    }
`

function Sidebar() {
    const sidebarOptions = [
        {
            id: '/',
            title: 'Create Blog'
        },
        {
            id: '/blogs',
            title: 'All Blogs',
        },
        {
            id: '/media',
            title: 'Media',
        },
    ];

    const activeStyle = {
        color: 'white'
    }

    return (
        <Wrapper>
            {
                sidebarOptions.map(({id, title})=> <Button
                    style={{justifyContent: "flex-start"}}
                    content={(
                        <NavLink
                            to={id}
                            key={id}
                            activeStyle={activeStyle}
                            style={{
                                color: '#ffffffa3',
                                width: '100%',
                                textAlign: 'left'
                            }}
                            exact
                        >
                            { title }
                        </NavLink>
                    )}
                    key={id}
                    fullWidth
                />)
            }
        </Wrapper>
    )
}

export default Sidebar;
