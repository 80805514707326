import React, { useEffect, useContext, useState } from 'react';
import Styled from 'styled-components';
import InfiniteScroll from "react-infinite-scroll-component";
import { axiosClient, getAllMedia } from '../../../Utils/api';
import { ToastContext } from '../../../Context';
import SingleMedia from './SingleMedia';

function AllMedia() {
    const [media, setMedia] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const  { setToastDetails } = useContext(ToastContext);

    const fetchData = async () => {
        let { url } = getAllMedia;
        try {
            let skip = media.length;
            let res = await axiosClient.get(`${url}?limit=6&skip=${skip}`);
            if(res.status === 200 && (res.data.data.length > 0)) {
                setMedia([...media, ...res.data.data]);
                setHasMore(Boolean(res.data.data.length === 6));
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setToastDetails({
                show: true,
                message: error.message
            })
        }
    }
    

    const printMedia = () => {
        return (
            <InfiniteScroll
                dataLength={media.length}
                next={()=> fetchData()}
                hasMore={hasMore}
                loader={<h4>Loading more Data ...</h4>}
                style= {{ 
                    display: 'grid',
                    overflow: 'hidden',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '10px'
                }}
            >
                { media.map((media, index)=><SingleMedia media={media} key={index}/>)}
            </InfiniteScroll>
        )
    }

    useEffect(()=>{
        setLoading(true);
        const fetchData = async () => {
            let url = `${getAllMedia.url}?limit=10`
            try {
                let res = await axiosClient.get(url);
                if(res.status === 200) {
                    setMedia(res.data.data);
                }
            } catch (error) {
                setToastDetails({
                    show: true,
                    message: error.message
                })
            }
        }
        fetchData();
        setLoading(false);
    }, [setToastDetails])

    if(loading && !media.length) {
        return (
            <p> Loading... </p>
        )
    }
    return (
        <Wrapper>
            <BlogWrapper>
                {
                    printMedia()
                }
            </BlogWrapper>
        </Wrapper>
    )
}

const Wrapper = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const BlogWrapper = Styled.div`
`

export default AllMedia
