import React, { useEffect } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ options = [], name="", labelId="labelId", onChange = null, selected = [] }) {
  const classes = useStyles();
  const [Options, setOptions] = React.useState([]);

  const handleChange = (event) => {
    setOptions(event.target.value);
    onChange({name, value: event.target.value});
  };

  const checkItemExistence = (id, array) => {
    return array.includes(id)
  }

  const showSelected = selected => {
    let filteredArr = options.filter(item => selected.includes(item.id));
    let finalArr = filteredArr.map(item=>item.title);
    return finalArr.join(',');
  }

  useEffect(()=>{
    setOptions(selected);
  }, [selected])

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>Categories</InputLabel>
      <Select
        labelId={labelId}
        id={labelId}
        multiple
        value={Options}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected=> showSelected(selected)}
        MenuProps={MenuProps}
      >
        {options.map(({id, title}) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={checkItemExistence(id, Options)} />
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
