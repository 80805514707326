import React from 'react';
import { Route } from 'react-router-dom';

import Header from '../../Components/Header';
import Routes from '../index';

function Index() {
    
    const RoutesWithHeader = [];
    if(Routes.length) Routes.forEach(route=>route.header? RoutesWithHeader.push(route.path): null);
    
    return (
        <Route path={RoutesWithHeader} component={Header} exact/>
    )
}

export default Index
