import Dashboard from './Dashboard.manager';
import Media from './Media';
import AllBlogs from './AllBlogs';
import Login from '../Login';
const manage = {
    Dashboard,
    AllBlogs,
    Media,
    Login,
}

export default manage;