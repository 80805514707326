import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { TINY_API } from '../../Utils/common';

function EditorComponent({initialContent, height=500, onChange = null}) {
    const handleEditorChange = (content) => {
      onChange(content);
    }
    return (
        <Editor
         value={initialContent}
         apiKey={TINY_API}
         init={{
           width: '100%',
           height,
           menubar: true,
           skin: "oxide-dark",
           content_css: "dark",
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
         }}
         onEditorChange={handleEditorChange}
       />
    )
}

export default EditorComponent
