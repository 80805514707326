import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxLabels(props) {
  const handleChange = () => {
    props.onChange({
        name: props.name,
        value: !props.checked
    })
  };

  return (
    <FormControlLabel
        control={
        <Checkbox
            checked={props.checked || false}
            onChange={handleChange || null}
            name={props.name || 'check'}
            color={props.color || 'primary'}
            disabled = { props.isDisabled }
        />
        }
        label={props.label || 'label'}
    />
  );
}
