import React, { useEffect, useState } from 'react';
import { 
  AuthContext,
  ToastContext,
  initialToastDetails,
  initialAuthDetails,
} from './Context';
import routes from './Routes';
import {Route as RouterRoute, useLocation, useHistory, Switch, Redirect} from 'react-router-dom';
import Header from './Routes/Header';
import Sidebar from './Routes/Sidebar';
import { axiosClient } from './Utils/api';
import Snackbar from './Components/Snackbar';
import './App.css';
import Emitter from './Utils/EventEmitter';
import { Fragment } from 'react';
import Manager from './Components/Manager';
import Styled from 'styled-components';

function App() {

  const { Login } = Manager;
  const [ authDetails, setAuthDetails ] = useState({...initialAuthDetails});
  const [ toastDetails, setToastDetails ] = useState({...initialToastDetails});  
  
  const history = useHistory();
  const location = useLocation();
  
  useEffect( () => {
    Emitter.addListener('newAccessRefreshToken', data=>{
      if(data.loggedIn) {
        setAuthDetails({
          // ...authDetails,
          user:{
            aToken: data.aToken,
            rToken: data.rToken,
          },
          loggedIn: true,
          loading: false,
        })
        let oldData = JSON.parse(localStorage.getItem('auth')) || {};
        localStorage.setItem('auth', JSON.stringify({
          ...oldData,
          aToken: data.aToken,
          rToken: data.rToken,
        }))
      } else {
        setAuthDetails({
          // ...authDetails,
          loggedIn: false,
          loading: false
        })
        localStorage.removeItem("auth")
      }
    })
    let asynFunc = async() => {
      const auth = JSON.parse(localStorage.getItem("auth"))
      if (auth?.aToken) {
          setAuthDetails({
            ...authDetails,
            loading: true,
          })
          try {
              // get admin data from api
              await axiosClient.get("/admin/me")
              setAuthDetails({
                ...authDetails,
                loggedIn: true,
                loading: false
              })
              // if 500, remove localstorage data
              // if 401, re-obtain access/refresh token data
          } catch (error) {
              if(error?.response?.status !== 401) {
                setAuthDetails({
                  // ...authDetails,
                  loggedIn: false,
                  loading: false
                })
                localStorage.removeItem("auth")
                return
              }
          }
      }
    }
    asynFunc();
  }, []);

  useEffect(()=>{
    localStorage.setItem('prevRoute', location.pathname);
    if(authDetails.loggedIn && location.pathname === "/login") {
      history.push('/')
    }
  }, [authDetails, history, location])

  
  return (
    <AuthContext.Provider value={{ authDetails, setAuthDetails }} >
      <ToastContext.Provider value={{ toastDetails, setToastDetails }}>
        {authDetails.loading? <div> Loading </div>:
          <Fragment>
          <Header />
          <Sidebar />
          <Wrapper>
            <Switch>
              { routes.map((route, index) =>  <RouterRoute key={index} path={route.path} exact={route.exact} render={()=>(
                route.isPrivate && !authDetails.loggedIn? <Redirect to="/login" /> : <route.component />
              )}/>) }
            </Switch>
          </Wrapper>
          <Snackbar />
          </Fragment>
        }
        {!authDetails.loading && !authDetails.loggedIn ? <RouterRoute path="/login" component={Login}/> : null}
      </ToastContext.Provider>
    </AuthContext.Provider>
  );
}

const Wrapper = Styled.div`
  padding: 0 20px;
  padding-top: 100px;
  display: flex;
  width: calc(100% - 200px);
  min-height: 100vh;
  position: absolute;
  right: 0;
  background: ${props=>props.theme};
  overflow-x: scroll;
`

export default App;
