import React, { useCallback, useContext, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Editor from '../Editor';
import { BlogContext, ToastContext } from '../../Context';
import ChipInput from 'material-ui-chip-input'
import Reuseable from '../../Components/Manager/reuseable';
import { createBlog, updateBlog, axiosClient } from '../../Utils/api';
import Options from './Options';
import Labels from './labels';
import { fetchBlogDetails } from '../../Utils/api';

function Dashboard() {
    const history = useHistory();
    const location = useLocation();

    const { blogDetails, setBlogDetail } = useContext(BlogContext);
    const { toastDetails ,setToastDetails } = useContext(ToastContext);
    const [isInternalAricle, setIsInternalAricle]= useState(true);
    
    const update = location.pathname.includes('update');
    const { Button, ImagePreview, Checkbox } = Reuseable;
    const decideKey = isInternalAricle? 'internal': 'external';

    const handleChange = ({name, value}) => {
      setBlogDetail({
        ...blogDetails,
        [name]: value
      });
    }

    const showErrorToast = useCallback(error => {
      setToastDetails({
        ...toastDetails,
        show: true,
        message: error.message
      })
    }, [setToastDetails, toastDetails])
    const params = useParams();

    const prepare = () => {
      const {title, categories, metaDescription, metaKeywords, content, publishOnApp, imageURL, url} = blogDetails;
      let obj  ={
        title,
        categories,
        createFeedForArticle: publishOnApp,
        description: metaDescription,
        contentHTML: content,
        imageUrl: imageURL,
        url: url,
        metaKeywords
      }
      if(params.id && update) {
        obj.id = params.id;
        delete obj.createFeedForArticle
      }
      if(!isInternalAricle) {
        delete obj.contentHTML
      } else {
        delete obj.url
      }
      return obj;
    }

    const changeToBlogContextFormat = obj=> {
      let {categories, content, description, imageUrl, title, url, metaKeywords } = obj;
      return{
        title,
        categories,
        content,
        metaDescription: description,
        imageURL: imageUrl,
        url: url,
        publishOnApp: true,
        metaKeywords
      }
    }

    const handleEditorChange = content => {
      handleChange({name: 'content', value: content});
    }

    const handleMetaKeywordsChange = (keywords) => {
      handleChange({ name: 'metaKeywords', value: keywords.map(k => k.trim()).join(', ') })
    }

    const handleSave = async () => {
      const data = prepare();
      try {
        if (data.contentHTML && !data.metaKeywords) throw new Error('Meta keywords are required!')

        let { url } = update? updateBlog : createBlog;
        let res = {};
        if(update) {
          res = await axiosClient.patch(url, data);
        }else {
          res = await axiosClient.post(url, data);
        }
        if (res.status === 200) {
          history.push('/blogs')
          setToastDetails({
            ...toastDetails,
            show: true,
            message: `${update? 'Updated' : 'Created'} Successfully!`
          })
        }
      }
      catch (error) {
        // console.log(error.response)
        showErrorToast(error);
      }
    }

    useEffect(()=>{
      const { id } = params;
      if(id) {
        let {url} = fetchBlogDetails;
        url = `${url}?articleId=${id}`
        const fetchArticleData = async () => {
          try {
            const res = await axiosClient.get(url);
            if(res.status === 200) {
              setBlogDetail(changeToBlogContextFormat(res.data.data));
              setIsInternalAricle(res.data.data.isInternalArticle);
            }
          }
          catch (error) {
            showErrorToast(error);
          }
        }
        fetchArticleData();
      }
    }, [params, setBlogDetail, showErrorToast]);

    return (
      <div style={{
        position: 'relative',
        width: '100%'
      }}>
      <ChooseBlogType>
        <Checkbox
          label="Is Internal Article"
          checked={isInternalAricle}
          isDisabled={update}
          onChange={()=> setIsInternalAricle(!isInternalAricle)}
        />
      </ChooseBlogType>
      <Options isUpdate={update} isInternalAricle={isInternalAricle} labels={Labels[decideKey]}/>
      <ImagePreview src={blogDetails.imageURL} />
      {isInternalAricle && <Editor
        initialContent={blogDetails.content}
        onChange={content=>handleEditorChange(content)}
      />}
      {
        isInternalAricle &&
        <>
          <p>Meta Keywords</p>
          <ChipInput
            defaultValue={blogDetails.metaKeywords ? blogDetails.metaKeywords.split(',') : []}
            onChange={handleMetaKeywordsChange}
          />
        </>
      }
      <FooterWrapper>
        <Button
          title={update ? 'Update' : 'Save'}
          color="primary"
          variant="contained"
          onClick={()=>handleSave()}
        />
      </FooterWrapper>
    </div>
  )
}

const FooterWrapper = Styled.div`
  width: 100%;
  position: relative;
  display: flex;
  grid-gap: 5px;
  margin-top: 10px;
`
const ChooseBlogType = Styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  box-sizing: border-box;
`

export default Dashboard
