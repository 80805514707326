import Manager from '../Components/Manager';

const { Dashboard, AllBlogs, Media } = Manager;
const Routes = [
    {
        path: '/',
        component: Dashboard,
        header: true,
        exact: true,
        isPrivate: true,
        sidebar: true,
    },
    {
        path: '/update/:id',
        component: Dashboard,
        header: true,
        exact: true,
        isPrivate: true,
        sidebar: true,
    },
    {
        path: '/blogs',
        component: AllBlogs,
        header: true,
        exact: true,
        isPrivate: true,
        sidebar: true,
    },
    {
        path: '/media',
        component: Media,
        header: true,
        exact: true,
        isPrivate: true,
        sidebar: true,
    },
];

export default Routes;