class Dispatcher {
    constructor () {
        this.events = {};   
    }
    addListener (event, callback) {
        // Check if the callback is not a function
        if (typeof callback !== 'function') {
            return false;
        }
        // Check if the event is not a string
        if (typeof event !== 'string') {
            return false;
        }
            
        // Create the event if not exists
        if (this.events[event] === undefined) {
            this.events[event] = {
                listeners: []
            }
        }
            
        this.events[event].listeners.push(callback);
    }
    removeListener (event, callback) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            return false;
        }
            
        this.events[event].listeners = this.events[event].listeners.filter(listener => {
            return listener.toString() !== callback.toString(); 
        });
    }
    dispatch (event, details) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            return false;
        }
        this.events[event].listeners.forEach((listener) => {
            listener(details);
        });
    }
    removeAllListeners (event) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            return false;
        }
        delete this.events[event]
    }
}

const Emitter = new Dispatcher();
export default Emitter;
