import React from 'react'
import { Link } from 'react-router-dom';
import Reuseable from '../../reuseable';
import Styled from 'styled-components';

function Index({blog}) {
    const {OpenInNew, Copy} = Reuseable;
    const {title, imageUrl, _id: id, url} = blog;
    let noImage = 'https://i.stack.imgur.com/y9DpT.jpg';
    return(
        <Wrapper>
            <Link to={`/update/${id}`}>
                <img src={imageUrl? imageUrl : noImage} alt={title} />
                <div className="blog-content">
                        <h3>
                            {title}
                        </h3>
                </div>
            </Link>
            <ControlsWrapper>
                <OpenInNew url={url}/>
                <Copy content={url}/>
            </ControlsWrapper>
        </Wrapper>
    )
}

const Wrapper = Styled.div`
    position: relative;
    transition: 0.5s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 20%)
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    .blog-content {
        cursor: pointer;
        padding: 0 10px;
        box-sizing: border-box;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
`
const ControlsWrapper = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    grid-gap: 10px;
`
export default Index
