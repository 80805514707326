import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';

function Delete({ onClick }) {
    return (
        <DeleteIcon onClick={onClick} style={{ cursor: onClick? 'pointer': 'initial' }}/>
    )
}

export default Delete
