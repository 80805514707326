import React from 'react';
import Styled from 'styled-components';

const ImageWrapper = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    img {
        max-width: 500px;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
`
function ImagePreview({ src = ''}) {
    return (src && (
        <ImageWrapper>
            <img src={src} alt="ImageURL preview" />
        </ImageWrapper>
    ))
}

export default ImagePreview
