import React, { useContext } from 'react';
import { Fragment } from 'react';
import Styled from 'styled-components';
import Reuseable from '../../Components/Manager/reuseable';
import UploadMediaButton from './UploadMediaButton'
import { BlogContext } from '../../Context';
import { options } from '../../Utils/common';

const InputBoxes = Styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const Dropdown = Styled.div`
  margin-bottom: 10px;
  alignItems: 'center'
`

const Title = Styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const FlexRight = Styled.div`
  min-width: 250px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto
`

const DropdownAndMedia = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

function Options({ isUpdate, isInternalAricle, labels }) {
    const { blogDetails, setBlogDetail } = useContext(BlogContext);
    const { MultiDropdown, Input, Checkbox} = Reuseable;
    const  {
      title= 'Title',
      description= 'Meta Description',
      imageURL= 'Header Image URL',
      URL= 'URL'
    } = labels;

    const handleChange = ({name, value}) => {
      setBlogDetail({
        ...blogDetails,
        [name]: value
      });
    }
    return (
        <Fragment>
            <Title>
              <Input
                variant="outlined"
                label={title}
                fullWidth={true}
                name="title"
                value={blogDetails.title}
                onChange={(e)=>handleChange(e.target)}
              />
            </Title>
            <InputBoxes>
              <Input
                label={description}
                variant="outlined"
                fullWidth={true}
                name="metaDescription"
                value={blogDetails.metaDescription}
                onChange={(e)=>handleChange(e.target)}
              />
              <Input
                label={imageURL}
                fullWidth={true}
                variant="outlined"
                name="imageURL"
                value={blogDetails.imageURL}
                onChange={(e)=>handleChange(e.target)}
              />
            </InputBoxes>
            {!isInternalAricle && <Input
              label={URL}
              fullWidth={true}
              variant="outlined"
              name="url"
              value={blogDetails.url}
              onChange={(e)=>handleChange(e.target)}
            />}
            <DropdownAndMedia>
                <Dropdown>
                  <MultiDropdown options={options} name='categories' labelId="categories" onChange={obj=>handleChange(obj)} selected={blogDetails.categories}/>
                </Dropdown>
              <FlexRight>
                {!isUpdate && <Checkbox
                    isDisabled={isUpdate || false}
                    label="Publish On App"
                    onChange={obj=>handleChange(obj)}
                    name="publishOnApp"
                    checked={blogDetails.publishOnApp}
                  />
                }
                <UploadMediaButton />
              </FlexRight>
            </DropdownAndMedia>
        </Fragment>
    )
}

export default Options
