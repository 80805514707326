import React from 'react';
import Styled from 'styled-components';
import LogOut from '../Logout';
import { Assets } from '../../Utils/common';

const Wrapper = Styled.div`
    width: 100%;
    height: 70px;
    background-color: #22338a;
    color: white;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    position: fixed;
    z-Index: 2;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    img {
        width: 80px;
    }
`

const TextSpan = Styled.span`
    margin-left: 10px;
    margin-top: auto;
`

function header() {

    const { Logo } = Assets;
    return (
        <Wrapper>
            <img src={Logo.src} alt={Logo.alt} />
            <TextSpan> Dashboard </TextSpan>
            <LogOut />
        </Wrapper>
    )
}

export default header;
