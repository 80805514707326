import React from 'react';
import { Route } from 'react-router-dom';

import Sidebar from '../../Components/Sidebar';
import Routes from '../index';

function Index() {
    
    const RoutesWithSidebar = [];
    if(Routes.length) Routes.forEach(route=>route.sidebar? RoutesWithSidebar.push(route.path): null);
    
    return (
        <Route path={RoutesWithSidebar} component={Sidebar} exact/>
    )
}

export default Index
