import Button from '../../Reuseable/Button';
import Checkbox from '../../Reuseable/Checkbox';
import Input from '../../Reuseable/Input';
import MultiDropdown from '../../Reuseable/MultiDropdown';
import ImagePreview from '../../Reuseable/ImagePreview';
import OpenInNew from '../../Reuseable/Icons/OpenInNew';
import Copy from '../../Reuseable/Icons/Copy';
import Pagination from '../../Reuseable/Pagination';
import Delete from '../../Reuseable/Icons/Delete';
import WithHover from '../../Reuseable/WithHover';

const Reuseable = {
    Button,
    Checkbox,
    Input,
    MultiDropdown,
    ImagePreview,
    OpenInNew,
    Copy,
    Delete,
    Pagination,
    WithHover
}

export default Reuseable;