import React, { useContext } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import firebase from '../../services/firebase';

import { AuthContext } from '../../Context';
import Button from '../Reuseable/Button';
import { axiosClient } from '../../Utils/api';

const Wrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
`
function LogOut({ afterLogOutPath }) {
  
  const history = useHistory();
  let { authDetails, setAuthDetails } = useContext(AuthContext);
  const handleLogOut =() => {
    const auth = JSON.parse(localStorage.getItem('auth')) || {}
    if (auth?.rToken) {
      axiosClient.patch('/admin/logout', {}, {
        headers: {
          'r-token': auth.rToken
        }
      })
    }
    setAuthDetails({
      ...authDetails,
      loggedIn: false
    })
    firebase.auth().signOut()
      .catch(console.error)
      .finally(() => {
        history.push( afterLogOutPath || '/login' )
        localStorage.removeItem('auth')
      })
  };

  if(!authDetails.loggedIn) {
    return (<></>)
  } else {
    return(
      <Wrapper >
        <Button
          title="Sign Out"
          onClick={ ()=> handleLogOut() }
          variant="contained"
          color="secondary"
        />
      </Wrapper>
    )
  }
}

export default LogOut
