import axios from 'axios';
import Emitter from '../Utils/EventEmitter';

let baseURL
switch (process.env.REACT_APP_CUSTOM_NODE_ENV) {
  case "development":
    baseURL = "http://localhost:8000/v1"
    break;
  case "test":
    baseURL = "http://52.14.177.11/api/v1"
    break
  case "production":
    baseURL = "https://api.voila.expert/v1"
    break
  default:
    baseURL = "http://52.14.177.11/api/v1";
}

export const createBlog = {
  url: `/admin/createArticle`,
  method: 'POST'
};
export const getBlogs = {
  url: `/admin/getArticles`,
};
export const uploadImage = {
  url: `/admin/uploadImage`,
};
export const fetchBlogDetails  = {
  // pass articleId in params
  url: `/articles/fetchArticle`,
}
export const updateBlog = {
  url: `/admin/editArticle`,
}
export const getAllMedia = {
  url: `/admin/getArticleMedia`,
}
export const deleteMedia = {
  url: `/admin/deleteArticleMedia`,
}
const instance = axios.create({
  baseURL,
})
instance.interceptors.request.use(function (config) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (auth?.aToken) {
    const { aToken: token } = auth
    config.headers = {
      ...(config.headers || {}),
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
  return config
})

instance.interceptors.response.use(resp => resp, function (error) {
  const originalRequest = error.config;
  console.log(error);
  if (error.response.status === 401 && originalRequest.url.endsWith("newAccessRefreshToken")) {
    localStorage.removeItem("auth")
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (!auth?.rToken) {
        localStorage.removeItem("auth")
        return Promise.reject(error)
      }

      return axiosClient.put('/admin/newAccessRefreshToken',
        {},
        {
          headers: {
            "r-token": auth.rToken
          }
        })
        .then(res => {
            if (res.data.data?.aToken) {
              const { aToken, rToken } = res.data.data
              localStorage.setItem("auth", JSON.stringify({
                ...auth,
                aToken,
                rToken 
              }));
              Emitter.dispatch('newAccessRefreshToken', {
                loggedIn: true,
                aToken,
                rToken 
              })
              return axios({
                ...originalRequest,
                headers: {
                  Authorization: `Bearer ${aToken}`
                }
              });
            }
        })
      .catch(error=>{
        Emitter.dispatch('newAccessRefreshToken', {
          loggedIn: false,
        })
        return Promise.reject(error);
        // return axios(originalRequest);
      })
  } else {
    return Promise.reject(error);
  }
})

export const axiosClient = instance;
