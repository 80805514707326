import Logo from '../Assets/logo.svg';
import Google from '../Assets/google-signin.png';

export const TINY_API = "2h79r2acwovd8oa2fenwy0641m15ldpww5z805blp2xes16i";

export const options = [
    {
        id: 'Sales & Growth',
        title: 'Sales & Growth'
    },
    {
        id: 'Technology',
        title: 'Technology'
    },
    {
        id: 'Funding',
        title: 'Funding'
    },
    {
        id: 'Public Relations',
        title: 'Public Relations'
    },
    {
        id: 'Business',
        title: 'Business'
    },
    {
        id: 'Legal & Taxation',
        title: 'Legal & Taxation'
    },
    {
        id: 'Digital Marketing',
        title: 'Digital Marketing'
    },
    {
        id: 'Others',
        title: 'Others'
    }
];

export const Assets = {
    Logo: {
        src: Logo,
        alt: 'Logo'
    },
    SignIn: {
        google: {
            src: Google,
            alt: 'Google Sign In'
        }
    }
};
