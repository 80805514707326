import React, { useEffect, useContext, useState } from 'react';
import Styled from 'styled-components';
import InfiniteScroll from "react-infinite-scroll-component";
import { axiosClient, getBlogs } from '../../../Utils/api';
import { ToastContext } from '../../../Context';
import SingleBlog from './SingleBlog';

function AllBlogs() {
    const [blogs, setBlogs] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const  { setToastDetails } = useContext(ToastContext);

    const fetchData = async (params = '') => {
        let url = `${getBlogs.url}?${params}`;
        try {
            let res = await axiosClient.get(url);
            if(res.status === 200) {
                setBlogs([...blogs, ...res.data.data]);
                setHasMore(Boolean(res.data.data.length));
            }
        } catch (error) {
            setToastDetails({
                show: true,
                message: error.message
            })
        }
    }
    
    const fetchMoreData = async () => {
        let parameters = '';
        if(blogs.length) {
            let lastItem = blogs[blogs.length-1];
            const { _id, publishedAt } = lastItem;
            if(_id && publishedAt) {
                parameters = `lastArticleId=${_id}&lastArticlePublishedAt=${publishedAt}`
            }
        }
        await fetchData(parameters);
    }

    const printBlogs = () => {
        return (
            <InfiniteScroll
                dataLength={blogs.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading more Data ...</h4>}
                style= {{ 
                    display: 'grid',
                    overflow: 'hidden',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '10px'
                }}
            >
                { blogs.map((blog, index)=><SingleBlog blog={blog} key={index}/>)}
            </InfiniteScroll>
        )
    }

    useEffect(()=>{
        setLoading(true);
        const fetchData = async () => {
            let url = `${getBlogs.url}`
            try {
                let res = await axiosClient.get(url);
                if(res.status === 200) {
                    setBlogs(res.data.data);
                }
            } catch (error) {
                setToastDetails({
                    show: true,
                    message: error.message
                })
            }
        }
        fetchData();
        setLoading(false);
    }, [setToastDetails])

    if(loading && !blogs.length) {
        return (
            <p> Loading... </p>
        )
    }
    return (
        <Wrapper>
            <BlogWrapper>
                {
                    printBlogs()
                }
            </BlogWrapper>
        </Wrapper>
    )
}

const Wrapper = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const BlogWrapper = Styled.div`
`

export default AllBlogs
