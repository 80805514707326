import React from 'react';
import { OpenInNew } from '@material-ui/icons';

function OpenInNewIcon({ url = "#"}) {
    return (
        <a href={url} target="_blank" rel="noreferrer">
            <OpenInNew style={{ cursor:'pointer'}}/>
        </a>
    )
}

export default OpenInNewIcon
