import React, { useState } from 'react'
import Styled from 'styled-components';


const HoveredWrapper = Styled.div``;
const Wrapper = Styled.div``;

function Index({ hoverComponent, children  }) {
    let [isHovered, setHover] = useState(false);
    
    if( isHovered && children ) {
        return (
            <HoveredWrapper onMouseLeave={()=> setHover(!isHovered)}>
                { hoverComponent }
            </HoveredWrapper>
        )
    }
    return (
        <Wrapper onMouseEnter={()=> setHover(!isHovered)}>
            { children }
        </Wrapper>
    )
}

export default Index
