import React, { useContext } from 'react'
import { ToastContext } from '../../../Context'
import { copyToClipboard } from '../../../Utils/copyToClipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

function Copy({ content }) {
    const { toastDetails, setToastDetails } = useContext(ToastContext);
    
    const handleClick = () => {
        copyToClipboard(content);
        setToastDetails({
            ...toastDetails,
            show: true,
            message: `Copied!`
        })
    }
    
    return (
        <FileCopyIcon onClick={()=> handleClick()} style={{ cursor: content? 'pointer': 'initial' }}/>
    )
}

export default Copy
