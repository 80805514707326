import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ToastContext } from '../../Context';

const SimpleSnackbar = () =>{

    const { toastDetails, setToastDetails } = useContext(ToastContext);

    const handleClose = (event) => {
        setToastDetails({
            ...toastDetails,
            show: false,
            message: ''
        })
    };

  return (
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={toastDetails.show}
        message= {toastDetails.message}
        onClose={()=>handleClose()}
        autoHideDuration={4000}
        action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=> handleClose()}>
                <CloseIcon fontSize="small" />
            </IconButton>
        }
    />
  );
}

export default SimpleSnackbar;
