import React, { useEffect, useContext } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';


import Auth from '../Auth';
import { AuthContext } from '../../Context';

const Wrapper = Styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white
`

function Login() {
  const history = useHistory();
  const { authDetails } = useContext(AuthContext);
  
  useEffect(()=>{
    if(authDetails.loggedIn) {
      history.push('/');
    }
  }, [history, authDetails.loggedIn])
  return (
      <Wrapper>
        < Auth/>
      </Wrapper>
  )
}

export default Login
