import { axiosClient } from './api';

function noop() {}

export const uploadMedia = (blob, filename, { cbOnProgress=noop, cbOnError=noop, cbOnComplete=noop }) => {
    const formData = new FormData();
    formData.append('file', blob, filename);
    axiosClient.post('/admin/uploadImage', formData, {
        onUploadProgress: cbOnProgress
    }).catch(cbOnError)
    .then(cbOnComplete)

    // $.ajax({
    //     type: 'POST',
    //     url: baseURL + ,
    //     data: formData,
    //     processData: false,
    //     contentType: false,
    //     xhr: () => {
    //         const xhr = new window.XMLHttpRequest();
    //         xhr.upload.addEventListener('progress', cbOnProgress, false);
    //         xhr.addEventListener("error", cbOnError, false);
    //         return xhr;
    //     }
    // }).done(resp => {
    //     cbOnComplete(resp);
    // }).catch(err => {
    //     console.error('Upload media failed:', err);
    //     cbOnError(err);
    // });
};