import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyAvh9ra9WnlwXfoHIHuagF36AYb7Kuc44w",
  authDomain: "voila-9938f.firebaseapp.com",
  databaseURL: "https://voila-9938f.firebaseio.com",
  projectId: "voila-9938f",
  storageBucket: "voila-9938f.appspot.com",
  messagingSenderId: "85516150457",
  appId: "1:85516150457:web:7414f5cdecddcafaf25d68",
  measurementId: "G-YYJF1P45YD"
};

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;
