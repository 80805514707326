import React, { useContext } from 'react';
import Styled from 'styled-components';
import { AuthContext } from '../../Context';
import { Assets } from '../../Utils/common';
import Reuseable from '../Manager/reuseable';
import { axiosClient} from '../../Utils/api';
import firebase from 'firebase'

const ImageWrapper = Styled.div`
  width: 200px;
  img {
    width: 100% !important;
  }
`

function Auth() {
    const { setAuthDetails } = useContext(AuthContext);
    const { Button } = Reuseable;
    
    const login = async () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        try {
        await firebase.auth().signInWithPopup(provider)
        let currentUser = firebase.auth().currentUser;
        let idToken = await currentUser.getIdToken();
        const response = await axiosClient.post("/admin/loginWithGoogle", { idToken });
        const { aToken, rToken, username } = response.data.status
        const obj = {
          username,
          aToken,
          rToken
        };
        setAuthDetails({
          loggedIn: true,
          user: obj
        });
        localStorage.setItem('auth', JSON.stringify(obj));
      } catch (error) {
        
      }
    }

    return (
      <Button
        onClick={login}
        content={<ImageWrapper><img src={Assets.SignIn.google.src} alt={Assets.SignIn.google.alt} /></ImageWrapper>}
      />
    )
}

export default Auth
