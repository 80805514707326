import { createContext } from 'react';

export const Theme = {
    light: 'white'
}

export let initialAuthDetails = {
    loggedIn: false,
    user: {},
    loading: false
}

export let initialToastDetails = {
    show: false,
    message: ''
}

export const initialBlogDetails = {
    title: '',
    categories: [],
    metaDescription: '',
    imageURL: '',
    content: '',
    url: '',
    publishOnApp: true,
    metaKeywords: ''
}

export const AuthContext = createContext(initialAuthDetails);
export const BlogContext = createContext(initialBlogDetails);
export const ToastContext = createContext(initialToastDetails);
