const labels = {
    internal: {
        title: 'Title',
        description: 'Meta Description',
        imageURL: 'Header Image URL',
        URL: 'URL'
    },
    external: {
        title: 'Title',
        description: 'Description',
        imageURL: 'Image URL',
        URL: 'URL'
    }
}

export default labels;
