import React, {useState} from 'react'
import Reuseable from '../../reuseable';
import Styled from 'styled-components';
import { axiosClient, deleteMedia } from '../../../../Utils/api';

function Index({media}) {
    const [hovering, setHovering] = useState(false);
    const {OpenInNew, Copy, Delete } = Reuseable;
    const {url } = media;
    let noImage = 'https://i.stack.imgur.com/y9DpT.jpg';
    
    const handleDeleteMedia = async id  => {
        if (!window.confirm('Are you sure you want to delete it? This is irreversible!')) {
            return
        }
        if(id) {
            try {
                let res = await axiosClient.delete(deleteMedia.url, { data: {
                    mediaId: id
                } });
                let jsonData = await res.json();
                console.log({ jsonData, res })
            } catch (error) {
                console.log(error)
            }
        }
        window.location.reload()
    }

    return(
        <Wrapper>
            <img 
                src={url? url : noImage} 
                alt="alt" 
                onMouseOver={()=>setHovering(!hovering)}
            />
            {hovering && <ControlsWrapper 
                onMouseLeave={()=>setHovering(!hovering)}
            >
                    <OpenInNew url={url}/>
                    <Copy content={url}/>
                    <Delete onClick={() => handleDeleteMedia(media._id)} />
                </ControlsWrapper>
            }
        </Wrapper>
    )
}

const Wrapper = Styled.div`
    position: relative;
    transition: 0.5s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 20%)
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    .blog-content {
        cursor: pointer;
        padding: 0 10px;
        box-sizing: border-box;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
`
const ControlsWrapper = Styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    grid-gap: 10px;
    background: rgb(255 255 255 / 57%);
    width: 100%;
    height: 200px;
`
export default Index
