import React, { useState } from 'react';
import DashboardComponent from '../Dashboard';
import { BlogContext, initialBlogDetails } from '../../Context';

function Dashboard() {
    const [blogDetails, setBlogDetail] = useState(initialBlogDetails);
    return (
        <BlogContext.Provider value={{ blogDetails, setBlogDetail }}>
            <DashboardComponent />
        </BlogContext.Provider>
    )
}

export default Dashboard
